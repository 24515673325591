.navbar {
    max-width: 1300px;
    flex: 1;
    margin: auto;
    background: #284271;
    display: flex;
    justify-content: flex-start;
}

.navbar a {
    font-size: 20px;
    padding: 10px 2px;
    color: rgb(255, 255, 255);
    flex: 1;
    line-height: 1.2;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
}

.navbar a:hover {
    background: #5685d7;
    color: white;
}