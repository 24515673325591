.timer {
    font-size: 100px;
    line-height: 1;
}

.container {
    position: absolute;
    color: #ffffff;
    bottom: 20px;
    left: 10px;
    z-index: 99;
    box-shadow: 0 0 27px 29px rgba(0,0,0,.2);
    background: rgba(0,0,0,.2);
}