.container {
    height:100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.task_container {
    height: calc(100% - 65px);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 345px;
    overflow: hidden;
}

.container h2{
    color: white;
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0;
}

.answer {
    height: 38px;
    margin: 0 10px;
    border-radius: 4px;
    border: none;
    padding: 0 10px;
}

.task_answer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.task_answer_title {
    color: #fff;
    font-size: 15px;
}

.transparent {
    opacity: 0;
}

.task_details {
    height: 100%;
}

.task_item {
    padding: 15px;
    margin-bottom: 15px;
    background: rgb(26, 58, 121);
    border-radius: 16px;
    color: white;
    font-size: 18px;
}

.task_item * {
    margin: 0;
    font-size: 18px !important;
    font-weight: 400 !important;
    background: transparent !important;
    color: white !important;
    font-family: "Myriad Pro", Myriad, 'Calibri', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.task_item img {
    max-width: 100%;
    height: auto !important;
    display: block;
    margin-bottom: 10px;
}

.title_block {
    margin-bottom: 20px;
    color: #ffffff;
}
.answer_row {
    display: flex;
    flex-flow: row wrap;
    margin-left: -5px;
    margin-top: 5px;
    word-break: break-word;
}

.id_question {
    text-align: right;
    color: #748cbd;
    margin-bottom: 5px;
}

.question_items {
    display: flex;
    flex-flow: row wrap;
    border-bottom: 1px solid #3386db;
    padding-bottom: 15px;
    margin: 15px 0 15px -5px;
}

.answer_item {
    padding: 5px 7px;
    background: #34669c;
    cursor: pointer;
    color: #ffffff;
    font-weight: 400;
    border-radius: 8px;
    font-size: 14px;
    margin: 5px;
}

@media(max-width: 1600px) {
    h2 {
        font-size: 1.2rem !important;
        line-height: 1.6;
    }
    .id_question {
        font-size: 12px;
    }
    .task_item, .task_item * {
        font-size: 14px !important;
    }
    .answer_item {
        font-size: 12px;
    }
    .container {
        margin-bottom: 30px;
    }
    .title_block {
        margin-bottom: 10px;
    }
}