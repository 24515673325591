.row {
    max-width: 300px;
    margin-bottom: 10px;
    display: flex;
}

.label {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
}

.input_container {
    display: flex;
    justify-content: space-between;
}

.input_container input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
    width: 100px;
    height: 30px;
    margin-left: 15px;
}

.controls {
    display: flex;
    justify-content: flex-end;
}

.psy_container .row:first-child {
    display: none;
}