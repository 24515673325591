.container {
    position: relative;
    flex: 1;
    background: #070707;
    overflow: hidden;
}

.interlocutor {
    height: 100%;
    margin: auto;
    display: block;
    max-width: 100%;
    position: relative;
    z-index: 20;
    box-shadow: 0 0 50px 5px rgba(0,0,0,.3);
}

.own {
    position: absolute;
    width: 260px;
    left: 15px;
    top: 15px;
    border: 1px solid #90908f;
    z-index: 21;
}

.recording {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 30;
}

.videoLayer {
    position: absolute;
    bottom: 5px;
    right: 150px;
    z-index: 20;
    padding: 10px;
    color: white;
    font-size: 23px;
}


.controls_button {
    width: 40px;
    background: transparent;
    border: none;
    outline: none !important;
}

.applicantLeave {
    position: absolute;
    z-index: 98;
    top: 0;
    height: 100%;
    background: #315697;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5em;
    line-height: 1;
    color: #fff;
    font-weight: bold;
    padding: 2em;
    text-align: center;
    border-top: 2px solid #ffffff;
}

.videoControls {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 130;
    padding: 10px;
}

.videoControls button {
    margin-left: 10px;
    opacity: .6;
    transition: all .1s ease-in-out;
}

.videoControls button:hover {
    opacity: 1;
}

.remoteVideo_container {
    background: black;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    border: 1px solid #90908f;
    z-index: 110;
}

.remoteVideo_container video {
    width: 260px;
    height: 195px;
}

.videoBg {
    opacity: 0;
    display: none;
}

.remoteVideo_active .videoBg {
    display: block;
    opacity: 1;
    position: absolute;
    z-index: 0;
    width: 200% !important;
    height: unset !important;
    max-width: unset !important;
    top: -50%;
    left: 0;
    filter: blur(20px);

}

.remoteVideo_active {
    position: static;
    width: 100%;
    height: 100%;
    z-index: 100;
    border: 0;
    max-width: 100%;
}

.remoteVideo_active video {
    width: auto;
    height: 100%;
    max-width: 100%;
}