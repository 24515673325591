.modal_bg {
    background: rgba(0,0,0, 0.47);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 130;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal_container {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    border-radius: 8px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 40px;
    z-index: 140;
}

.close_modal {
    position: absolute;
    width: 25px;
    height: 25px;
    top: -35px;
    right: -35px;
    cursor: pointer;
    border: none;
    background: none;
    z-index: 22;
}
