.container {
    max-width: 100%;
    text-align: center;
    width: 450px;
    background: #ffffff;
    border-radius: 8px;
    padding: 40px;
    margin: 60px auto auto;
}

.title {
    text-align: center;
    margin-bottom: 15px;
}

.error {
    position: absolute;
    background: #ffffff;
    width: 280px;
    margin: 50px 40px 50px;
    padding: 17px;
    text-align: center;
    color: #a2a2a2;
    font-size: 20px;
    box-shadow: 0 0 11px 5px rgba(0, 0, 0, 0.1);
}

.error p {
    margin-bottom: 20px;
}

.user {
    margin-bottom: 15px;
}

.user img {
    width: 96px;
    height: 96px;
}

.pdfload {
    margin-top: 20px;
    font-size: 19px;
}

.pdfload a {
    color: black;
}

.pdfload a:hover {
    color: #0b0776;
}
