.button {
    margin-left: 10px;
}

.controls {
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date {
    display: flex;
}

.date input {
    width: auto;
    height: 35px;
    font-weight: 400;
}

.date p {
    font-size: 25px;
    font-family: "Myriad Pro", sans-serif;
    color: rgb(49, 86, 151);
    line-height: 1.2;
    margin-right: 15px;
    font-weight: 500;
}


.container {
    max-width: 1720px;
    margin: auto;
}
