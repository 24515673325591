.buttonPanel {
    position: fixed;
    bottom: 0;
    right: 0;
    background: #ffffff;
    z-index: 10;
    padding: 20px;
}

.buttonPanel button {
    margin-right: 10px;
}