.container {
    position: absolute;
    z-index: 98;
    top: 0;
    height: 100%;
    background: #315697;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5em;
    line-height: 1;
    color: #fff;
    font-weight: bold;
    padding: 2em;
    text-align: center;
    border-top: 2px solid #ffffff;
}