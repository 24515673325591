.container {
    background: #315697;
    padding: 15px;
    display: flex;
}

.logo {
    display: flex;
}

.logoImage {
    width: 128px;
    height: 128px;
    background-position: center center;
    border-radius: 50em;
    background-size: 95px;
    background-repeat: no-repeat;
    background-color: white;
}

.logoTitleContainer {
    display: flex;
    align-items: center;
    margin-left: 25px;
}

.logoTitle {
    font-size: 16px;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: left;
    max-width: 230px;
    text-transform: uppercase;
}

.app_title_container {
    position: absolute;
    right: 0;
    left: 0;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
}

.mo_title {
    font-size: 20px;
}

.app_title {
    font-size: 40px;
}

@media (max-width: 1600px) {
    .app_title {
        font-size: 24px;
    }
    .mo_title {
        font-size: 14px;
    }
    .logoTitle {
        font-size: 14px;
    }
    .app_title_container {
        position: relative;
    }
    .logoImage {
        width: 100px;
        height: 100px;
    }
}