.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 80px;
    background-size: cover;
}

.title {
    color: #ffffff;
    font-size: 60px;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    line-height: 1;
}

.phone_block {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    text-align: center;
}
.phone_block p {
    font-size: 16px;
    font-weight: 400;
    color: #5091d0;
    margin-bottom: 5px;
}
.phone_block a {
    font-size: 16px;
    color: #5091d0;
    font-weight: 400;
    font-weight: 400;
}