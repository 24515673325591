.button {
    border: none;
    max-height: 38px;
    height: 100%;
    font-size: 15px;
    line-height: 1;
    padding: 9px;
    border-radius: 3px;

    background-color: #336fb9;
    color: #ffffff;
    letter-spacing: .4px;
    user-select: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.button:active, .button:focus {
    outline: none;
}

.button:hover {
    background: #0b0776;
    color: #fff;
}

.button:disabled {
    background: #c0c0c0;
    cursor: initial;
}
.button:disabled:hover {
    box-shadow: none;
}

.redButton {
    background-color: #e2907a;
}

.blueButton {
    background-color: #336fb9;
}

.greenButton {
    background-color: #1e7e34;
}

.darkBlueButton {
    background-color: #315697;
}

.transparentButton {
    background-color: transparent;
    color: #4c6fb6;
    border: 1px solid #336fb9;
    padding: 10px 26px;
}
.button.transparentButton:disabled:hover {
    color: #4c6fb6;
}

@media print {
    .button {
        display: none;
    }
}
