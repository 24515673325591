.container {
    display: flex;
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
}

.left {
    flex: 1 1 73%;
    max-width: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.right {
    flex: 0 0 27%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.header {
    background: #315697;
    padding: 15px;
    display: flex;
    height: 16vh;
}

.logo {
    display: flex;
    align-items: center;
}

.logoImage {
    width: 128px;
    height: 128px;
    background-size: cover;
    background-position: center center;
    border-radius: 50em;
    max-height: 100%;
}

.logoName {
    color: #fff;
    font-size: 20px;
    margin-left: 25px;
    line-height: 1.2;
}

.app_title_container {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
}

.mo_title {
    font-size: 20px;
}

.app_title {
    font-size: 40px;
}

.student {
    background: #fff;
    color: #315697;
    height: auto;
    padding-bottom: 5px;
}

.student_wrapper {
    padding: 15px;
    padding-bottom: 0;
}

.student_info {
    /*border-bottom: 1px solid #315697;*/
    display: flex;
    justify-content: flex-end;
    padding-bottom: 5px;
}

.student_task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
}


.student_taskname {
    font-size: 30px;
    padding-top: 5px;
}

.student_descr {
    text-align: right;
    padding-right: 15px;
    font-size: 15px;
}

.student_descr button {
    margin-top: 10px;
}

.student_photo {
    width: 155px;
    height: 210px;
    border: 1px solid #939393;
    background-size: cover;
    background-position: center center;
}

.student_photo img {
    width: 155px;
    height: auto;
    display: block;
}

.student_fio {
    font-size: 25px;
    line-height: 1.2;
}

.task {
    background: #274582;
    flex: 1;
    overflow: hidden;
}

.task_wrapper {
    padding: 15px;
    height: calc(100% - 70px);
}

.task_details {
    text-align: center;
}

.task_details img {
    max-width: 80%;
}

.task_number, .task_answer {
    font-weight: bold;
    font-size: 30px;
    color: #ffffff;
}

.task_answer span {
    font-size: 20px;
    font-weight: normal;
}

.progressInfo {
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

.progressInfo_current {
    color: #aaa;
    font-size: 16px;
}

.progressInfo_button {
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
}

.progressbar {
    display: block;
    height: 5px;
    width: 100%;
    background: #336fb9;
    position: relative;
}

.progressLine {
    position: absolute;
    top: 0;
    left: 0;
    width: 67%;
    height: 5px;
    background: #3386db;
}

.btn_block {
    height: 100px;
}

.buttons {
    padding: 15px;
}

.buttons div {
    display: flex;
}

.buttons button {
    width: 100%;
    margin: 0 20px 15px 0px;
}

.buttonsControl a{
    flex: 1;
}

.buttonsControl a:first-child {
    margin-right: 15px;
}

.ticket_on_select {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-size: 25px;
    margin-top: 25px;
}


@media (max-width: 1600px) {
    .buttons button {
        font-size: 12px;
    }
    .student_fio {
        font-size: 16px;
    }
    .student_photo {
        width: 108px;
        height: auto;
        border: none;
    }
    .student_photo img {
        width: 100%;
        height: auto;
    }
    .student_descr {
        font-size: 13px;
    }
    .progressInfo {
        padding: 10px;
    }
    .progressInfo_current,
    .progressInfo_button {
        font-size: 14px;
    }
    .student {
        height: 170px;
    }
    .buttons {
        height: 75px;
        height: 75px;
        padding: 5px;
    }
    .buttons button {
        max-height: 30px;
        margin: 0px 5px 5px 0px;
    }
    .buttonsControl a:first-child {
        margin-right: 5px;
    }
}

