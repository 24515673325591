.container {
  display: flex;
  height: 100%;
}

.left {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.right {
  width: 26.45%;
  height: 100%;
  background: aliceblue;
}

/*controls*/
.controls {
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  display: flex;
}

.date p {
  font-size: 25px;
  font-family: 'Myriad Pro', sans-serif;
  color: rgb(49, 86, 151);
  line-height: 1.2;
  margin-right: 15px;
  font-weight: 500;
}

.mainTitle h1 {
  font-size: 30px;
  font-family: 'Myriad Pro', sans-serif;
  color: rgb(197, 197, 197);
  line-height: 1.2;
  text-align: center;
  font-weight: 400;
  margin: 0;
}

/*card*/
.listCard {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
  width: 20%;
  min-width: 300px;
}

.listCardSelected {
  background: #3386db !important;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
}

.cardHeader {
  display: flex;
}

.studentImg {
  width: 100px;
}

.studentImg img {
  max-width: 100%;
  width: 100px;
  display: block;
  height: auto;
}

.card {
  width: 320px;
  background: #315697;
  padding: 10px;
  cursor: pointer;
}

.studentInfo {
  flex: 1;
  margin: 0 10px;
  font-size: 13px;
  font-family: 'Myriad Pro', sans-serif;
  color: rgb(255, 255, 255);
  line-height: 1.2;
  text-align: right;
}

.studentInfo div {
  margin-bottom: 5px;
}

.studentInfo h4 {
  font-size: 20px;
  font-family: 'Myriad Pro', sans-serif;
  color: rgb(255, 255, 255);
  line-height: 1.2;
  text-align: right;
  font-weight: 400;
  margin-bottom: 10px;
}

.studentYear span {
  display: inline-block;
}

.studentYear span:first-child {
  margin-right: 3px;
}

.underline {
  text-decoration: underline;
}

.cardContent {
  display: flex;
  border-top: 1px solid #ffffff;
  font-size: 14px;
  font-family: 'Myriad Pro', sans-serif;
  color: rgb(255, 255, 255);
  line-height: 1.2;
  text-align: left;
  padding: 15px 7px;
  margin-top: 10px;
}

.cardContent_title {
  margin-bottom: 15px;
  margin-right: 15px;
}

.sessionTime {
  font-size: 22px;
}

@media (max-width: 1400px) {
  .app_title_container {
    position: static;
  }
}
